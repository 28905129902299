<template>
    <div class="suplliers-management">
        <div class="suplliers-management-tools">
            <el-input style="width: 30%;" v-model="search" placeholder="חיפוש..." />
            <el-button @click="show_add_supplier=true"  style="margin: 0 5px;" type="primary">הוספת ספק</el-button>
        </div>
        <div v-loading="is_pending" class="suplliers-management-content">
            <h2 v-if="!slice_data.length">לא קיימות רשומות!</h2>
            <table v-if="slice_data.length">
                <tr>
                    <th>מס ספק</th>
                    <th>שם ספק</th>
                    <th>פעולות</th>
                </tr>
                <template v-for=" s in slice_data" :key="s">
                    <tr>
                        <td>{{s.supplier_num}}</td>
                        <td>{{s.supplier_name}}</td>
                        <td>
                            <i @click="handle_edit_supplier(s)" class="material-icons icon edit-icon">edit</i>
                            <i @click="handle_delete_supplier(s)" class="material-icons icon delete-icon" >delete</i>
                        </td>
                    </tr>
                </template>
            </table>
        </div>

        <div class="suplliers-management-pagination">
            <el-pagination 
                v-model:currentPage="pagination.current_page"
                background 
                :page-size="pagination.number_per_page"
                layout="prev,pager,next"
                :total="filter_by_search.length" 
            />
        </div>
        <AddSupplier 
            v-if="show_add_supplier"
            @close="show_add_supplier=false" 
            :edit_supplier = selected_supplier_to_edit
        />
    </div>
</template>

<script>
import { computed, defineAsyncComponent, ref } from 'vue';
import {suppliers,get_suppliers_from_db,delete_supplier_from_db_and_local} from './store'
import {slide_pop_error,alert_confirm} from '../../../../Methods/Msgs'


export default {
    components:{
        AddSupplier:defineAsyncComponent(() => import('./AddSupplier.vue'))
    },
    setup () {
        
        const pagination = ref({
            current_page:1,
            number_per_page:20,
        })

        const slice_data = ref(computed(()=>{
            return filter_by_search.value.slice((pagination.value.current_page-1)*pagination.value.number_per_page,
            ((pagination.value.current_page-1)*pagination.value.number_per_page)+pagination.value.number_per_page)
        }))

        const search = ref('')
        const selected_supplier_to_edit = ref(null)

        const show_add_supplier = ref(false)
        const is_pending = ref(false)

        const handle_edit_supplier = (supplier) => {
            selected_supplier_to_edit.value = JSON.parse(JSON.stringify(supplier));
            show_add_supplier.value = true
        }

        const handle_delete_supplier = async(supplier) => {
            try{
                alert_confirm(`למחוק את ${supplier.supplier_name} ?`)
                .then(async res => {
                    if(res.isConfirmed){
                        is_pending.value = true
                        await delete_supplier_from_db_and_local(supplier)
                        is_pending.value = false
                    }
                })
            }catch(err){
                console.error(err.message)
                slide_pop_error(err.message)
            }
        }

        const filter_by_search = computed(() => {
            if(!search.value) return suppliers.value
            return suppliers.value.filter(s => {
                if(s.supplier_name.includes(search.value)) return s
                if(s.supplier_num.includes(search.value)) return s
            })
        })

        const init = async() => {
            try{
                is_pending.value = true
                await get_suppliers_from_db()
                is_pending.value = false
            }catch(err){
                console.error(err.message)
            }
        }


        init()

        return {
            handle_delete_supplier,
            handle_edit_supplier,
            init,
            suppliers,
            search,
            show_add_supplier,
            is_pending,
            selected_supplier_to_edit,
            filter_by_search,
            pagination,
            slice_data
        }
    }
}
</script>

<style  scoped>
    .suplliers-management{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .suplliers-management-tools{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .suplliers-management-content{
        width: 100%;
        flex: 1;
        overflow-y: auto;
    }
    .suplliers-management-pagination{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 18px;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: var(--purple);
        color: white;
        position: sticky;
        top: 0;
        font-size: 20px;
    }
    @media only screen and (max-width: 600px){
        table th{
          position:unset;  
        }
    }

    .icon{
        user-select: none;
        cursor: pointer;
        margin: 0 5px;
    }
    .edit-icon{
        color: var(--warning);
    }
    .delete-icon{
        color: var(--danger);
    }

    ::v-deep(.el-pagination.is-background .btn-next i){
        transform: rotate(180deg);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    ::v-deep(.el-pagination.is-background .btn-prev i){
        transform: rotate(180deg);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    ::v-deep(.el-pagination.is-background .el-pager li:not(.disabled).active){
        background: var(--purple);
        color: #fff;
    }

</style>