import { ref } from "vue";
import { projectFirestore } from "../../../../../firebase/config";

const suppliers = ref([])

const orders = ref([])

/* suppliers */
const get_suppliers_from_db = async() => {
    try{
        const docs = await projectFirestore.collection("Applications")
        .doc('ZPWzy2s5SjbxIHG8XbcS').collection('Suppliers').get()
        suppliers.value =  docs.docs.map(doc => doc.data())
    }catch(err){
        throw new Error(err)
    }
}

const delete_supplier_from_db_and_local = async(s) => {
    try{
        const index = suppliers.value.findIndex(({uid}) => uid == s.uid)
        if(index != -1){
            await projectFirestore.collection("Applications")
            .doc('ZPWzy2s5SjbxIHG8XbcS').collection('Suppliers').doc(s.uid).delete()
            suppliers.value.splice(index,1)
        }
    }catch(err){
        throw new Error(err)
    }
}

const edit_supplier_in_db = async(supplier) => {
    try {
        const index = suppliers.value.findIndex(({uid}) => uid == supplier.uid)
        if(index != -1){
            suppliers.value[index] = supplier
            await projectFirestore.collection("Applications")
                .doc('ZPWzy2s5SjbxIHG8XbcS').collection('Suppliers').doc(supplier.uid)
                .set(supplier,{merge:true})
        }
    } catch(err) {
        throw new Error(err.message);
    }
}


const add_supplier_in_db = async(supplier) => {
    try {
        // Reference to the Suppliers collection
        const suppliersCollection = projectFirestore.collection("Applications")
            .doc('ZPWzy2s5SjbxIHG8XbcS').collection('Suppliers');
        
        // Query to check if supplier.num already exists
        const existingSupplierNumQuery = await suppliersCollection
            .where('supplier_num', '==', supplier.supplier_num)
            .get();

        // Query to check if supplier.name already exists
        const existingSupplierNameQuery = await suppliersCollection
            .where('supplier_name', '==', supplier.supplier_name)
            .get();

        // Check if either query returned any documents
        if (!existingSupplierNumQuery.empty || !existingSupplierNameQuery.empty) {
            // Either supplier.num or supplier.name already exists in the database
            console.log('Supplier number or name already exists in the database.');
            if(!existingSupplierNumQuery.empty) throw new Error(`מספר ספק: ${supplier.supplier_num} כבר קיים במערכת!`); 
            throw new Error(`שם ספק: ${supplier.supplier_name} כבר קיים במערכת!`); 
        }

        // If neither supplier.num nor supplier.name exists, proceed to add the new supplier
        
        const doc = suppliersCollection.doc();
        const data_obj = {
            uid: doc.id,
            supplier_name: supplier.supplier_name,
            supplier_num: supplier.supplier_num
        }
        await doc.set(data_obj);

        suppliers.value.unshift(data_obj)
        console.log('Supplier added successfully.');

    } catch(err) {
        throw new Error(err.message); // Make sure to throw a more specific error message
    }
}

/* orders */
const get_orders_from_db = async (status) => {
    let query;
    if (status) {
        // Status is provided, filter documents by status
        query = projectFirestore.collection("Applications")
        .doc('ZPWzy2s5SjbxIHG8XbcS')
        .collection('Orders') // Assuming you're querying a subcollection. Replace 'YourSubcollectionName' with the actual name.
        .where('status', '==', status); // Assuming the field name is 'status'
    } else {
        // Status is null, get all documents
        query = projectFirestore.collection("Applications")
        .doc('ZPWzy2s5SjbxIHG8XbcS')
        .collection('Orders'); // Replace 'YourSubcollectionName' with the actual subcollection name
    }

    try {
        const querySnapshot = await query.get();
        orders.value = querySnapshot.docs.map(doc => doc.data());
    } catch (err) {
        throw new Error(err)
    }
};

const update_status_order_in_db = async(order) => {
    try{
        const index = orders.value.findIndex(({uid}) => uid == order.uid)
        if(index!=-1){
            orders.value[index].status = order.status
            await projectFirestore.collection("Applications")
            .doc('ZPWzy2s5SjbxIHG8XbcS')
            .collection('Orders').doc(order.uid)
            .set({
                status:order.status
            },{merge:true})
        }else{
            throw new Error('לא ניתן לבצע עדכון סטטוס - שגיאת מערכת!')
        }
    }catch(err){
        throw new Error(err)
    }
}

const delete_order_from_local_and_db = async(order) => {
    try{
        const index = orders.value.findIndex(({uid}) => uid == order.uid)
        if(index != -1){
            orders.value.splice(index,1)
            await projectFirestore.collection("Applications")
            .doc('ZPWzy2s5SjbxIHG8XbcS')
            .collection('Orders').doc(order.uid).delete()
        }else{
            throw new Error('לא ניתן לבצע פעולת מחיקת הזמנה - שגיאת מערכת!')
        }
    }catch(err){
        throw new Error(err)
    }
}

export{
    suppliers,
    orders,
    delete_supplier_from_db_and_local,
    get_suppliers_from_db,
    edit_supplier_in_db,
    add_supplier_in_db,
    get_orders_from_db,
    update_status_order_in_db,
    delete_order_from_local_and_db
}